import { db, getDocData } from "@/google/firestore"
import { downloadFromStorage } from "@/google/storage"
import { CallHistoryRecord, CallResult, ManualOutboundCallResult } from "@/models/CallHistory"
import { Dayjs } from "dayjs"
import { collection, getCountFromServer, getDocs, limit, orderBy, query, QueryDocumentSnapshot, startAfter, Timestamp, where } from "firebase/firestore"
import memoize from 'lodash/memoize'

const DEFAULT_QUERY_LIMIT = 1000;

type DBCallHistoryFilter = {
    startDate?: Dayjs | null,
    callHistoryId?: string
}
type CallHistoryFilter = {
    nextCallStartDate?: Dayjs | null,
    nextCallEndDate?: Dayjs | null,
    minCallDuration?: number,
    endDate?: Dayjs | null,
    uid?: string,
    callResult?: CallResult | ManualOutboundCallResult | "",
    callListId?: string, 
    scriptId?: string,
    keyword?: string,
    phoneNumber?: string
} & DBCallHistoryFilter

type CallHistoryCursor = {
    filter: CallHistoryFilter
    totalRecordsNum: () => Promise<number>
    fetchNext: (number?: number) => Promise<{ [historyId: string]: CallHistoryRecord }>
}

class CallHistoryCursorFactory {
    static create({ companyId, filter, recordsPerPage }: {
        companyId: string,
        filter: CallHistoryFilter,
        recordsPerPage: number
    }): CallHistoryCursor {
        if (filter.keyword) {
            return new CallHistoryCursorWithLocalFilter({ companyId, filter });
        } else {
            return new CallHistoryCursorWithoutLocalFilter({ companyId, filter, recordsPerPage });
        }
    }
}

const createCallHistoryQuery = ({ companyId, filter, lim, lastDoc }: {
    companyId: string,
    filter: CallHistoryFilter,
    lastDoc?: QueryDocumentSnapshot
    lim?: number
}) => {
    const filters = []
    filters.push(where("createdAt", "<", filter.endDate ? filter.endDate.toDate() : Timestamp.now().toDate()))

    if(filter.startDate)
        filters.push(where("createdAt", ">=", new Date(filter.startDate.toDate().setHours(0, 0, 0, 0))))
    if(filter.callResult)
        filters.push(where("result", "==", filter.callResult))
    if(filter.callListId)
        filters.push(where("callListId", "==", filter.callListId))
    if(filter.scriptId)
        filters.push(where("scriptId", "==", filter.scriptId))
    if(filter.uid)
        filters.push(where("operatorId", "==", filter.uid))
    if(filter.minCallDuration)
        filters.push(where("callDuration", ">", filter.minCallDuration))
    if(filter.nextCallEndDate)
        filters.push(where("nextCallDate", "<=", new Date(filter.nextCallEndDate.toDate().setHours(24, 0, 0, 0))))
    if(filter.nextCallStartDate)
        filters.push(where("nextCallDate", ">=", new Date(filter.nextCallStartDate.toDate().setHours(0, 0, 0, 0))))
    if(filter.phoneNumber)
        filters.push(where("phoneNumber", "==", filter.phoneNumber))
    if(lim)
        filters.push(limit(lim))
    if(lastDoc)
        filters.push(startAfter(lastDoc))

    return query(collection(db, "companies", companyId, "callHistory"), orderBy("createdAt", "desc"), ...filters)
}

// ページネーションが無理なやつ
class CallHistoryCursorWithLocalFilter implements CallHistoryCursor {
    readonly filter: CallHistoryFilter
    private readonly companyId: string
    private history: { [historyId: string]: CallHistoryRecord }

    constructor({ companyId, filter }: {
        companyId: string,
        filter: CallHistoryFilter,
    }) {
        this.companyId = companyId
        this.filter = filter
    }

    totalRecordsNum = async (): Promise<number> => {
        if(!this.history) {
            this.history = await this.fetchNext()
        }

        return Object.keys(this.history).length
    }

    fetchNext = async (): Promise<{ [historyId: string]: CallHistoryRecord }> => {
        if(this.history)
            return this.history

        const filter = this.filter
        const q = createCallHistoryQuery({ companyId: this.companyId, filter: filter, lim: DEFAULT_QUERY_LIMIT })
        const querySnapshot = await getDocs(q)
        const locallyFiltered = await this.applyLocalFilter(querySnapshot.docs.map(doc => [
            doc.id,
            { ...doc.data(), id: doc.id } as CallHistoryRecord
        ]), filter)

        return Object.fromEntries(locallyFiltered)
    }

    private applyLocalFilter = async (history: [string, CallHistoryRecord][], filter: CallHistoryFilter) => {
        const filterConditionPromises = history.map(async ([id, record]) => {
            if(!filter.keyword)
                return true
    
            // キーワードによるフィルタリング
            try {
                const transcript = await downloadFromStorage({ path: `company/${this.companyId}/log/transcript/${record.id}.txt`, responseType: "text" }) as string
                return transcript.includes(filter.keyword)
            } catch(e) {
                console.error(e)
                return false
            }
        })

        const conditions = await Promise.all(filterConditionPromises)
        return history.filter((_, i) => conditions[i])
    }
}

// ページネーション用のクラス
// keywordによる絞り込みは不可
class CallHistoryCursorWithoutLocalFilter implements CallHistoryCursor {
    readonly filter: Omit<CallHistoryFilter, "keyword">
    private readonly companyId: string
    private readonly recordsPerPage: number
    private lastDoc?: QueryDocumentSnapshot

    constructor({ companyId, filter, recordsPerPage }: {
        companyId: string,
        filter: CallHistoryFilter,
        recordsPerPage: number
    }) {
        this.companyId = companyId
        this.filter = filter
        this.recordsPerPage = recordsPerPage
    }

    totalRecordsNum = memoize(async (): Promise<number> => {
        const q = createCallHistoryQuery({ companyId: this.companyId, filter: this.filter, lastDoc: this.lastDoc })
        const total = (await getCountFromServer(q)).data().count     // クエリによるフィルタで該当する全件数

        return total
    })

    fetchNext = async (count: number = 1): Promise<{ [historyId: string]: CallHistoryRecord }> => {
        const filter = this.filter

        // 特定のレコードだけ取得
        if(filter.callHistoryId) {
            const record = await getDocData(`/companies/${this.companyId}/callHistory/${filter.callHistoryId}`) as CallHistoryRecord
            return { [filter.callHistoryId]: record }
        }
        
        if(!filter.startDate && !filter.callHistoryId)
            return {}
    
        // 取得はページごとに行う
        const qLimit = createCallHistoryQuery({ companyId: this.companyId, filter: filter, lim: this.recordsPerPage * count, lastDoc: this.lastDoc })
        const querySnapshot = await getDocs(qLimit)
        this.lastDoc = querySnapshot.docs[querySnapshot.docs.length - 1]    // 次ページの開始地点
        const history: [string, CallHistoryRecord][] = []
        querySnapshot.forEach(snapshot => history.push([snapshot.id, snapshot.data() as CallHistoryRecord]))

        return Object.fromEntries(history)
    }
}

export { CallHistoryCursorFactory }
export type { CallHistoryFilter, CallHistoryCursor }