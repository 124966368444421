import { BreadcrumbRef, DSBreadcrumbs } from "@/components/DSBreadcrumbs"
import { Box, Container, FormControl, InputLabel, MenuItem, Select, Stack } from "@mui/material"
import softStyleProps from "@/styles/softStyleProps"
import { ScriptCard } from "@/features/Script/ScriptCard"
import { useScripts } from "@/hooks/useScripts"

const Script = () => {
  const scripts = useScripts()
  const breadcrumbRefs: BreadcrumbRef[] = [
    { title: "スクリプト設定" }
  ]

  return (
    <Container sx={{ py: 4 }} maxWidth="lg">
      <DSBreadcrumbs breadcrumbRefs={breadcrumbRefs}></DSBreadcrumbs>
      <Stack mt={2} gap={2}>
        {
          Object.entries(scripts).map(([id, script]) => (
            <ScriptCard key={id} script={script} scriptId={id}></ScriptCard>
          ))
        }
      </Stack>
    </Container>
  )
}

export default Script